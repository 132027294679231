import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ViewClient.css";
import { useParams } from "react-router-dom";
import Sidebar from "../SideBar/SideBar";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ClientDetails = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [clientEvents, setClientEvents] = useState([]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  // Fetch client details and events by ID
  useEffect(() => {
    axios
      .get(`https://demo.internsbee.in/api/enquiry/client/${clientId}`)
      .then((response) => {
        console.log("Client Details Response:", response.data);
        if (response.data && response.data.result.length > 0) {
          setClient(response.data.result[0].client_id); // Set client details
          setClientEvents(response.data.result); // Set client events
        }
      })
      .catch((error) => {
        console.error("Error fetching client details and events:", error);
      });
  }, [clientId]);

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = clientEvents.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(clientEvents.length / entriesPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="bg-indigo-100 flex-grow mt-16 h-full w-full min-h-screen vendor-content">
        <div className="p-6">
          <h3 className="text-xl font-bold mb-4 text-center">Client Details</h3>
          {/* Display selected client details */}
          {client && (
            <div className="flex justify-center gap-20 mb-6">
              <p><strong>Name:</strong> {client.full_name}</p>
              <p><strong>Email:</strong> {client.email}</p>
            </div>
          )}

          {/* Display client event details */}
          {clientEvents.length > 0 && (
            <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full border border-gray-300 rounded-lg">
          <thead className="rounded-2xl">
            <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
              <th className="px-6 py-2     text-left textbold text-black uppercase tracking-wider">
                Sr.No
              </th>
                  <th className="px-6 py-2     text-left text-4xs font-bold text-black uppercase tracking-wider">Event Name</th>
                    <th className="px-6 py-2     text-left text-4xs font-bold text-black uppercase tracking-wider">Event Date</th>
                    <th className="px-6 py-2     text-left text-4xs font-bold text-black uppercase tracking-wider">Budget</th>
                    <th className="px-6 py-2     text-left text-4xs font-bold text-black uppercase tracking-wider">Venue</th>
                    <th className="px-6 py-2     text-left text-4xs font-bold text-black uppercase tracking-wider">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((event,index) => (
                    <tr key={event._id}                  
                     className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300"
                    >
                    <td className="px-6  py-2  w-12 text-left text-2xs font-medium text-black uppercase tracking-wider">
                    {(currentPage - 1) * entriesPerPage + index + 1}
                  </td>
                      <td className="px-6 py-2     text-left text-2xs font-medium text-black tracking-wider">{event.event_name}</td>
                      <td className="px-6 py-2     text-left text-2xs font-medium text-black tracking-wider">{new Date(event.event_date).toLocaleDateString()}</td>
                      <td className="px-6 py-2     text-left text-2xs font-medium text-black tracking-wider">{event.event_budget}</td>
                      <td className="px-6 py-2     text-left text-2xs font-medium text-black tracking-wider">{event.event_venue}</td>
                      <td className="px-6 py-2     text-left text-2xs font-medium text-black tracking-wider">{event.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination Controls */}
          {clientEvents.length > entriesPerPage && (
            <div className="flex justify-center items-center py-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-200 text-gray-600 rounded-l hover:bg-gray-300 disabled:opacity-50"
              >
                <FaChevronLeft />
              </button>
              <span className="mx-2 text-gray-600">{currentPage} of {totalPages}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 bg-gray-200 text-gray-600 rounded-r hover:bg-gray-300 disabled:opacity-50"
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
