import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import "./EventReport.css";
import {
  FaSortAmountDown,
  FaSortAmountUp,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import Sidebar from "../SideBar/SideBar";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (!isNaN(date)) {
    return date.toLocaleDateString("en-GB");
  }
  return "Invalid Date";
};
const EnquiryReport = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 4;

  const fetchEventData = async () => {
    try {
      const response = await axios.get(
        "https://demo.internsbee.in/api/enquiry"
      );
      const eventData = response.data.result || []; // Ensure eventData is an array
      setEvents(eventData);
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterEvents(query, dateRange, selectedStatus);
  };

  const sortEvents = (order, data = null) => {
    const eventsToSort = data || filteredEvents;
    const sorted = [...eventsToSort].sort((a, b) => {
      const valueA = (a.event_date || "").toLowerCase();
      const valueB = (b.event_date || "").toLowerCase();

      if (order === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setFilteredEvents(sorted);
  };

  const exportToExcel = () => {
    const filteredData = filteredEvents.map((event) => ({
      EventName: event.event_name,
      Venue: event.event_venue,
      Subvenue: event.subvenue || "N/A", // Handle missing subvenue
      Event_Date: event.event_date,
      Guest_Number: event.guest_quantity,
      QuotationAmount: event.event_budget,
      Status: event.status,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wscols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    ws["!cols"] = wscols;

    // Add bold formatting to header
    const headerCellStyle = { font: { bold: true } };
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = { c: col, r: 0 }; // Header row is row 0
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (ws[cellRef]) {
        ws[cellRef].s = headerCellStyle;
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, "Events");
    XLSX.writeFile(wb, "enquiry_report.xlsx");
  };

  const filterEvents = (
    query = "",
    dateRange = { startDate: "", endDate: "" },
    status = "All"
  ) => {
    let filtered = events;

    if (status !== "All") {
      filtered = filtered.filter((event) => event.status === status);
    }

    if (query) {
      filtered = filtered.filter((event) =>
        event.event_name.toLowerCase().includes(query.toLowerCase())
      );
    }

    if (dateRange.startDate || dateRange.endDate) {
      const startDate = dateRange.startDate
        ? new Date(dateRange.startDate)
        : null;
      const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;

      filtered = filtered.filter((event) => {
        const eventDate = new Date(event.event_date);
        return (
          (!startDate || eventDate >= startDate) &&
          (!endDate || eventDate <= endDate)
        );
      });
    }

    setFilteredEvents(filtered);
    sortEvents(sortOrder, filtered); // Sort after filtering
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  useEffect(() => {
    filterEvents(searchQuery, dateRange, selectedStatus);
  }, [dateRange, searchQuery, selectedStatus, events]);

  const toggleSortOrder = () => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newOrder);
    sortEvents(newOrder);
  };

  const clearFilters = () => {
    setSelectedStatus("All");
    setSearchQuery("");
    setDateRange({ startDate: "", endDate: "" });
    filterEvents("", { startDate: "", endDate: "" }, "All");
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(filteredEvents.length / entriesPerPage);

  // Slice the filteredEvents based on the current page
  const paginatedEnquiries = filteredEvents.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );
  return (
    <div className="w-full  px-4 eventreport-content ">
      <div className="flex justify-center items-center w-full mb-6">
        <h2 className="text-4xl font-bold text-black mx-3">Enquiry Reports</h2>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-4">
        <div className="flex flex-col md:flex-row items-center gap-4 mt-3">
          <div className="flex items-center gap-2">
            <label className="mr-1">From:</label>
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) =>
                setDateRange({ ...dateRange, startDate: e.target.value })
              }
              className="p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="mr-1">To:</label>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) =>
                setDateRange({ ...dateRange, endDate: e.target.value })
              }
              className="p-2 border border-gray-300 rounded"
            />
          </div>
        </div>
        <div className="flex items-center">
          <p className="mr-4">
            Total number of events: {filteredEvents.length}
          </p>
          <button
            className="p-2 bg-blue-800 hover:bg-blue-400 text-white font-bold py-3 px-8 rounded-full"
            onClick={exportToExcel}
          >
            Export to Excel
          </button>
        </div>
      </div>

      <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full border border-gray-300 rounded-lg">
          <thead className="rounded-2xl">
            <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
              <th className="px-6 py-2 w-5     text-left textbold text-black uppercase tracking-wider">
                Sr.No
              </th>
              <th
                className="px-2 py-2 w-10     text-left text-4xs font-bold text-black uppercase tracking-wider"
                scope="col"
              >
                Event Name
              </th>
              <th
                className="px-2 py-2 w-5     text-left text-4xs font-bold text-black uppercase tracking-wider"
                scope="col"
                onClick={toggleSortOrder}
              >
                Event Date{" "}
                {sortOrder === "asc" ? (
                  <FaSortAmountDown />
                ) : (
                  <FaSortAmountUp />
                )}
              </th>
              <th
                className="px-2 py-2 w-5     text-left text-4xs font-bold text-black uppercase tracking-wider"
                scope="col"
              >
                Guest Number
              </th>
              <th
                className="px-2 py-2 w-36     text-left text-4xs font-bold text-black uppercase tracking-wider"
                scope="col"
              >
                Venue
              </th>
             
              <th
                className="px-2 py-2 w-28     text-left text-4xs font-bold text-black uppercase tracking-wider"
                scope="col"
              >
                Address
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedEnquiries.length > 0 ? (
              paginatedEnquiries.map((event, index) => (
                <tr
                  key={event._id || index}
                  className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300 ease-in-out "
                >
                  <td className="px-6 py-2 w-5  text-2xs font-medium text-black tracking-wider   text-left">
                    {index + 1}
                  </td>
                  <td className="px-2 py-2 w-10   text-2xs font-medium text-black tracking-wider   text-left">
                    {event.event_name}
                  </td>
                  <td className="px-2 py-2 w-5  text-2xs font-medium text-black tracking-wider  text-left">
                    {formatDate(event.event_date)}
                  </td>
                  <td className="px-2 py-2 w-5   text-2xs font-medium text-black tracking-wider   text-left">
                    {event.guest_quantity}
                  </td>
                  <td className="px-2 py-2 w-36   text-2xs font-medium text-black tracking-wider   text-left">
                    {event.event_venue}
                  </td>
                 
                  <td className="px-1 py-2 w-28   text-2xs font-medium text-black tracking-wider  text-left">
                    {event.client_id?.address}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="px-6 py-2 w-24 text-sm text-center">
                  No events found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="pagination  flex justify-center items-center mt-8">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            <FaChevronLeft />
          </button>
          <div className="pagination-info mx-2">
            {currentPage} of {totalPages}
          </div>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnquiryReport;
