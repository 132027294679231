import React, { useEffect, useState } from "react";
import { getAllQuotations } from "../../Services/ProposalApi"; // Adjust the import path as necessary
import Modal from "./Mode"; // Adjust the import path as necessary
import Sidebar from "../SideBar/SideBar";
import {
  FaCalendarAlt,
  FaEye,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from "../SearchInput/SearchInput";

const ViewProposal = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [quotations, setQuotations] = useState([]);
  const [filteredQuotations, setFilteredQuotations] = useState([]);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [quotationsPerPage] = useState(5); // Number of quotations per page

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterProposals = () => {
    let filtered = quotations;

    filtered = filtered.filter((quotation) => {
      const enquiry = quotation?.enquiry_id;

      // Check if enquiry exists before proceeding
      if (!enquiry) return false;

      return (
        (searchTerm
          ? enquiry.client_name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            enquiry.client_id?.full_name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            enquiry.event_name?.toLowerCase().includes(searchTerm.toLowerCase())
          : true) &&
        (startDate ? new Date(enquiry.event_date) >= startDate : true) &&
        (endDate ? new Date(enquiry.event_date) <= endDate : true)
      );
    });

    setFilteredQuotations(filtered);
  };

  useEffect(() => {
    const fetchQuotations = async () => {
      try {
        const response = await getAllQuotations();
        if (response.quotation && Array.isArray(response.quotation)) {
          setQuotations(response.quotation);
          setFilteredQuotations(response.quotation);
        } else {
          console.error("Unexpected data format:", response);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchQuotations();
  }, []);

  useEffect(() => {
    filterProposals();
  }, [searchTerm, startDate, endDate, quotations]);

  // Function to handle opening the modal
  const handleViewMore = (quotation) => {
    setSelectedQuotation(quotation);
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedQuotation(null);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (
      newPage >= 1 &&
      newPage <= Math.ceil(filteredQuotations.length / quotationsPerPage)
    ) {
      setCurrentPage(newPage);
    }
  };

  // Calculate total pages
  const totalPages = Math.ceil(filteredQuotations.length / quotationsPerPage);

  // Slice the filtered quotations based on the current page
  const currentQuotations = filteredQuotations.slice(
    (currentPage - 1) * quotationsPerPage,
    currentPage * quotationsPerPage
  );

  if (error) return <div>Error: {error}</div>;
  if (!filteredQuotations.length) return <div>Loading...</div>;

  return (
    // <div className="flex bg-indigo-100">
    //   <Sidebar />
    //   <div className="min-h-screen p-4 flex-grow-1 mt-20 w-full event-content">
    //     <div className="">
    <div className="w-full  px-4 proposal">
      <div className="flex justify-center items-center w-full mb-6">
        <h2 className="text-4xl font-bold text-black mx-3">View Proposal</h2>
      </div>

      <div className="flex flex-col md:flex-row md:justify-between w-full gap-4">
      <div className="mt-3 w-full md:w-1/2">
        <SearchInput
          placeholder="Search"
          onChange={handleSearchChange}
          style={{ width: "100%" }} // Full width on smaller screens
        />
      </div>
      <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-1/2">
        <div className="w-full max-w-xs">
          <label className="block text-lg font-medium text-gray-800 mb-2">
            From
          </label>
          <div className="relative w-full">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd-MM-yyyy"
              placeholderText="DD-MM-YYYY"
              className="w-full py-2 pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
            />
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
          </div>
        </div>

        <div className="w-full max-w-xs">
          <label className="block text-lg font-medium text-gray-800 mb-2">
            To
          </label>
          <div className="relative w-full">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd-MM-yyyy"
              placeholderText="DD-MM-YYYY"
              className="w-full py-2 pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
            />
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
          </div>
        </div>
      </div>
    </div>
      <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full border border-gray-300 rounded-lg">
          <thead className="rounded-2xl">
            <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
              <th className="px-6 py-2 w-36 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Sr.No
              </th>
              <th className="px-6 py-2 w-36 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Client Name
              </th>
              <th className="px-6 py-2 w-36 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Event Name
              </th>
              <th className="px-6 py-2 w-36 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Event Date
              </th>
              <th className="px-6 py-2 w-36 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Grand Total
              </th>
              <th className="px-6 py-2 w-36 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentQuotations.map((quotation, index) => (
              <tr
                key={quotation._id}
                className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300"
              >
                <td className="px-6 py-2 w-36 text-left text-2xs font-medium text-black uppercase tracking-wider">
                  {(currentPage - 1) * quotationsPerPage + index + 1}
                </td>

                <td className="px-6 py-2 w-36 text-left text-2xs font-medium text-black tracking-wider">
  {quotation.enquiry_id?.client_id?.full_name || "N/A"}
</td>

                <td className="px-6 py-2 w-36 text-left text-2xs font-medium text-black tracking-wider">
                  {quotation.enquiry_id?.event_name || "N/A"}
                </td>
                <td className="px-6 py-2 w-36 text-left text-2xs font-medium text-black tracking-wider">
                  {new Date(
                    quotation.enquiry_id?.event_date
                  ).toLocaleDateString() || "N/A"}
                </td>
                <td className="px-6 py-2 w-36 text-left text-2xs font-medium text-black tracking-wider">
                  {quotation?.grand_total || "N/A"}
                </td>
                <td className="px-6 py-2 w-36 text-left text-2xs font-medium text-black tracking-wider">
                  <button
                    onClick={() => handleViewMore(quotation)}
                    className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                  >
                    <FaEye />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        {filteredQuotations.length > 0 && (
          <div className="pagination flex justify-center items-center py-2 ">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button px-4 py-2  bg-gray-200 text-gray-600 rounded-l hover:bg-gray-300 disabled:opacity-50"
            >
              <FaChevronLeft />
            </button>
            <span className="pagination-info mx-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button px-4 py-2  bg-gray-200 text-gray-600 rounded-r hover:bg-gray-300 disabled:opacity-50"
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        quotation={selectedQuotation}
      />
    </div>
    //   </div>
    // </div>
  );
};

export default ViewProposal;
