import React, { useState, useEffect } from "react";
import {
  FaCalendarAlt,
  FaEye,
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from "../SearchInput/SearchInput";
import Sidebar from "../SideBar/SideBar";
import axios from "axios";
import "./ViewPayment.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const ViewPayment = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 4;

  const fetchPayments = async () => {
    try {
      const response = await axios.get(
        "https://demo.internsbee.in/api/advancepayment"
      );
      setPayments(response.data.data);
      setFilteredPayments(response.data.data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, startDate, endDate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const applyFilters = () => {
    let filtered = payments;

    if (startDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.payment_date) >= startDate
      );
    }
    if (endDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.payment_date) <= endDate
      );
    }
    if (searchTerm) {
      filtered = filtered.filter(
        (payment) =>
          payment.enquiry?.client_id?.full_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          payment.enquiry?.event_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }

    setFilteredPayments(filtered);
    setCurrentPage(1); // Reset to first page after filters are applied
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredPayments.length / entriesPerPage);
  const indexOfLastPayment = currentPage * entriesPerPage;
  const indexOfFirstPayment = indexOfLastPayment - entriesPerPage;
  const currentPayments = filteredPayments.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleViewMore = (details) => {
    setSelectedDetails(details);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedDetails(null);
  };

  return (
    <>
      {/* // <div className="flex bg-indigo-100">
    //   <Sidebar />
    //   <div className="min-h-screen p-4 flex-grow-1 mt-20 w-full"> */}
      {/* //     <div className="flex justify-center"> */}
      <div className="w-full h-full  px-4 vendor-content">
        <div className="flex justify-center items-center w-full mb-6">
          <h2 className="text-4xl font-bold text-black mx-3">View Payment</h2>
        </div>
        <div className=" flex flex-col justify-between w-full md:flex-row ">
          <div className="mt-3 w-full md:w-96">
            <SearchInput
              placeholder="Search by Name"
              onChange={handleSearchChange}
              className="w-full py-2 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800"
            />
          </div>
          <div className=" flex items-center justify-center gap-4">
            <div className="w-full max-w-xs">
              <label className="block text-lg font-medium text-gray-800 mb-2">
                From
              </label>
              <div className="relative w-full max-w-md">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="dd/mm/yy"
                  className="w-full  py-2    pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
                />
                <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
              </div>
            </div>
            <div className="w-full max-w-xs">
              <label className="block text-lg font-medium text-gray-800 mb-2">
                To
              </label>
              <div className="relative w-full">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="dd/mm/yy"
                  className="w-full  py-2    pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
                />
                <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto w-full mt-4 cursor-pointer">
          <table className="min-w-full border border-gray-300 rounded-lg">
            <thead className="rounded-2xl">
              <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
                <th className="px-3 w-5 py-2   text-left textbold text-black uppercase tracking-wider">
                  Sr.No
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Client Name
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Event Name
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Event Date
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Payment Date
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Total Amount
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Advance Amount
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Pending Amount
                </th>
                <th className="px-6  py-2  w-24   text-left text-4xs font-bold text-black uppercase tracking-wider">
                  View More
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPayments.map((item, index) => (
                <tr
                  key={item._id}
                  className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300"
                >
                  <td className="px-3 w-5 py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    {indexOfFirstPayment + index + 1}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    {item.enquiry?.client_id?.full_name}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    {item.enquiry?.event_name}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    {new Date(item.enquiry?.event_date).toLocaleDateString()}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    {new Date(item.payment_date).toLocaleDateString()}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    {item.quotation.grand_total.toFixed(2)}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    {item.advance_amount.toFixed(2)}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    {item.balance_amount.toFixed(2)}
                  </td>
                  <td className="px-6  py-2  w-24   text-left text-2xs font-medium text-black  tracking-wider">
                    <button
                      onClick={() => handleViewMore(item)}
                      className="bg-blue-800 text-white py-1 px-2 rounded full"
                    >
                      <FaEye />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {filteredPayments.length > 0 && (
          <div className="pagination flex justify-center items-center mt-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4  py-2    bg-gray-200 text-gray-600 rounded-l hover:bg-gray-300 disabled:opacity-50"
            >
              <FaChevronLeft />
            </button>
            <span className="mx-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-4  py-2     bg-gray-200 text-gray-600 rounded-r hover:bg-gray-300 disabled:opacity-50"
            >
              <FaChevronRight />
            </button>
          </div>
        )}

        {showDetailsModal && selectedDetails && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-xs w-full">
              <h3 className="text-xl font-bold mb-4 items-center flex justify-center">
                Payment Details
              </h3>
              <div className="p-2">
                <strong>Client Name:</strong>{" "}
                {selectedDetails.enquiry.client_name}
              </div>
              <div className="p-2">
                <strong>Event Name:</strong>{" "}
                {selectedDetails.enquiry.event_name}
              </div>
              <div className="p-2">
                <strong>Payment Date:</strong>{" "}
                {new Date(selectedDetails.payment_date).toLocaleDateString()}
              </div>
              <div className="p-2">
                <strong>Advance Amount:</strong>{" "}
                {selectedDetails.advance_amount}
              </div>
              <div className="p-2">
                <strong>Pending Amount:</strong>{" "}
                {selectedDetails.balance_amount}
              </div>
              <div className="p-2">
                <strong>Total Amount:</strong>{" "}
                {selectedDetails.quotation.grand_total}
              </div>
              <div className="flex justify-center">
                <button
                  onClick={handleCloseDetailsModal}
                  className="bg-blue-800 text-white  py-2    px-4 rounded mt-4"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default ViewPayment;
