import React from "react";
import TextField from "@mui/material/TextField";

// CustomTextField component
const InputField = ({
  type = "text",
  id,
  name,
  value,
  required = false,
  onChange,
  style,
  onInput,
  maxLength,
  label,
  ...props
}) => {
  return (
    <TextField
      type={type}
      id={id}
      name={name}
      value={value}
      required={required}
      onChange={onChange}
      onInput={onInput}
      inputProps={{ maxLength }} // maxLength attribute
      label={label}
      sx={{
        width: "10rem", // Set width to 10rem
        "& .MuiOutlinedInput-root": {
          height: "3rem", // Set height for the input field
          boxSizing: "border-box", // Include padding and border in the element's total width and height
          "& fieldset": {
            borderColor: "black", // Default border color
            transition: "border-color 0.3s ease", // Smooth border color transition
          },
          "&:hover fieldset": {
            borderColor: "blue", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "blue", // Border color when focused
          },
          "& input": {
            color: "black", // Text color
            height: "100%", // Ensure input text takes full height
            padding: "0.5rem", // Adjust padding to avoid text touching the border
            boxSizing: "border-box", // Include padding in height calculation
          },
          "& .MuiInputAdornment-root": {
            cursor: "pointer", // Change cursor to pointer for icon
            "& svg": {
              color: "black", // Default icon color
              transition: "color 0.3s ease", // Smooth color transition
              "&:hover": {
                color: "blue", // Icon color on hover
              },
            },
          },
        },
        "& .MuiFormLabel-root": {
          color: "black", // Label color
        },
        ...style, // Apply additional styles
      }}
      {...props}
    />
  );
};

export default InputField;
