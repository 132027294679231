import React, { useState, useEffect } from "react";
import ButtonBox from "../ButtonBox/ButtonBox";
import InputField from "../InputField/InputField";
import DatePickerInput from "../DatePickerInput/DatePickerInput";
import { useNavigate } from "react-router-dom";
// import Navbar from "../Navbar/Navbar";
import Sidebar from "../SideBar/SideBar";
import "./Enquiry.css";
import axios from "axios";

const AddEnquiry = () => {
  const [enquiry, setEnquiry] = useState({
    client_id: "",
    client_name: "",
    email: "",
    contact: "",
    address: "",
    event_name: "",
    event_budget: "",
    guest_quantity: "",
    event_venue: "",
  });
  console.log("enquiry", enquiry);
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Fetch clients on mount
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(
          `https://demo.internsbee.in/api/clients/`
        );
        console.log("API Response Data:", response.data); // Log data for debugging
        setClients(response.data.result || []); // Access 'result' key
      } catch (error) {
        console.error(
          "Error fetching clients:",
          error.response?.data || error.message
        );
        setClients([]); // Default to an empty array in case of error
      }
    };
    fetchClients();
  }, []);

  // Fetch selected client data when client ID changes
  useEffect(() => {
    if (selectedClientId) {
      const fetchClientData = async () => {
        try {
          const response = await axios.get(
            `https://demo.internsbee.in/api/clients/${selectedClientId}`
          );
          console.log("Selected Client Data:", response.data); // Log data for debugging
          const clientData = response.data.result; // Assuming 'result' contains the client data
          setSelectedClientData(clientData);

          // Update enquiry state with selected client data
          setEnquiry((prevEnquiry) => ({
            ...prevEnquiry,
            client_id: clientData._id || "",
            client_name: clientData.full_name || "",
            email: clientData.email || "",
            contact: clientData.contact_number || "",
            address: clientData.address || "",
          }));
        } catch (error) {
          console.error(
            "Error fetching client data:",
            error.response?.data || error.message
          );
          setSelectedClientData(null); // Reset if there's an error
        }
      };
      fetchClientData();
    }
  }, [selectedClientId]);

  const handleClientChange = (event) => {
    setSelectedClientId(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnquiry({
      ...enquiry,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setEventDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    // Prepare enquiry data with both manual input and selected client data
    const enquiryData = {
      ...enquiry,
      event_date: eventDate ? eventDate.toISOString() : null, // Convert event_date to ISO format
    };

    console.log("Final Enquiry Data for API:", enquiryData); // Debugging: Inspect enquiryData

    try {
      const response = await axios.post(
        "https://demo.internsbee.in/api/enquiry/add",
        enquiryData
      );
      console.log("Enquiry created successfully:", response.data);
      alert("Enquiry created successfully");
    } catch (error) {
      console.error(
        "Error creating enquiry:",
        error.response?.data || error.message
      );
      alert("Failed to create enquiry. Check console for details.");
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://demo.internsbee.in/api/event"
        );
        console.log("Event API Response:", response.data); // Log the response data to check structure

        // Check the structure of the response, and update the state accordingly
        if (Array.isArray(response.data)) {
          setData(response.data); // Assuming it's an array of events
        } else if (response.data.event) {
          setData(response.data.event); // Assuming the events are nested under 'event'
        } else {
          setData([]); // In case the structure is not as expected, set to an empty array
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        alert("Failed to load events. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleViewEnquiry = () => {
    navigate("/viewenquiry"); // Navigate to the View Enquiry page
  };
  const validate = () => {
    let tempErrors = {};

    // Ensure that all values being trimmed are strings
    if (!String(enquiry.client_name).trim())
      tempErrors.client_name = "Client name is required";

    if (!String(enquiry.email).trim()) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(enquiry.email)) {
      tempErrors.email = "Email is not valid";
    }

    if (!String(enquiry.contact).trim()) {
      tempErrors.contact = "Contact number is required";
    } else if (String(enquiry.contact).length !== 10) {
      tempErrors.contact = "Contact number must be 10 digits";
    }

    if (!String(enquiry.address).trim())
      tempErrors.address = "Address is required";

    if (!String(enquiry.event_name).trim())
      tempErrors.event_name = "Event name is required";

    if (!String(enquiry.event_budget).trim() || isNaN(enquiry.event_budget)) {
      tempErrors.event_budget = "Valid event budget is required";
    }

    if (
      !String(enquiry.guest_quantity).trim() ||
      isNaN(enquiry.guest_quantity)
    ) {
      tempErrors.guest_quantity = "Valid guest quantity is required";
    }

    if (!String(enquiry.event_venue).trim())
      tempErrors.event_venue = "Event venue is required";

    if (!eventDate) tempErrors.event_date = "Event date is required";

    setErrors(tempErrors);

    // Return true if no errors
    return Object.keys(tempErrors).length === 0;
  };

  return (
    // <div className="flex">
    //   <Sidebar />
    //   <div className="h-screen flex justify-center mt-10 flex-grow-1 bg-indigo-100 w-full ">
    <div className="w-full max-w-3xl px-4 ">
      <h1 className="text-4xl font-bold mb-7 text-center ">Add Enquiry</h1>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-6 md:flex-row md:space-x-4 mb-4">
        <div className="w-full max-w-md mx-auto">
        <select
          onChange={handleClientChange}
          value={selectedClientId}
          className="h-12 border border-black rounded px-3 py-2 w-full"
          style={{ width: '100%' }}
        >
          <option value="">Select a client</option>
          {clients.map((client) => (
            <option key={client._id} value={client._id}>
              {client.full_name}
            </option>
          ))}
        </select>
      </div>
      

          {selectedClientData && (
            <InputField
              label="Address"
              name="address"
              type="text"
              value={selectedClientData.address}
              readOnly // Set to readOnly since it's fetched data
            />
          )}
        </div>
        {selectedClientData && (
          <div className="flex flex-col gap-7 md:flex-row md:space-x-4 mb-4">
            <InputField
              label="Contact Number"
              name="contact"
              type="number"
              value={selectedClientData.contact_number || ""}
              readOnly // Set to readOnly since it's fetched data
            />
            <InputField
              label="Email"
              name="email"
              type="email"
              value={selectedClientData.email || ""}
              readOnly // Set to readOnly since it's fetched data
            />
          </div>
        )}
        <div className="flex flex-col gap-7 md:flex-row md:space-x-4 mb-4">
          <div className="w-full max-w-md mx-auto">
  <select
    name="event_name"
    value={enquiry.event_name}
    onChange={handleChange}
    className="h-12 border border-black rounded px-3 py-2 w-full"
  >
    <option value="">Select Event</option>
    {!loading && data.length > 0 ? (
      data.map((event) => (
        <option key={event._id} value={event.event_name}>
          {event.event_name}
        </option>
      ))
    ) : (
      <option disabled>Loading events...</option>
    )}
  </select>
</div>

          <InputField
            label="Event Venue"
            name="event_venue"
            type="text"
            value={enquiry.event_venue}
            onChange={handleChange}
            error={errors.event_venue}
            required
          />
        </div>
        <div className="flex flex-col gap-7 md:flex-row md:space-x-4 mb-4">
          <InputField
            label="Event Budget"
            name="event_budget"
            type="number"
            value={enquiry.event_budget}
            onChange={handleChange}
            error={errors.event_budget}
            required
          />
          <InputField
            label="Guest Quantity"
            name="guest_quantity"
            type="number"
            value={enquiry.guest_quantity}
            onChange={handleChange}
            error={errors.guest_quantity}
            required
          />
        </div>
        <div className="flex flex-col gap-7 md:flex-row md:space-x-4 mb-4">
          <DatePickerInput
            className="datepicker-enquiry"
            label="Event Date"
            selectedDate={eventDate}
            onDateChange={handleDateChange}
            error={errors.event_date}
            required
          />
        </div>
        <div className="text-right">
          <ButtonBox
            label="Add Enquiry"
            type="submit"
            buttonText="Submit"
            className="font-bold py-2 px-4 rounded"
          />
        </div>
      </form>
    </div>
    //   </div>
    // </div>
  );
};

export default AddEnquiry;
