import Chart from "./chart";
import { LuWallet } from "react-icons/lu";
import { CiMenuKebab } from "react-icons/ci";
import { IoPieChartOutline } from "react-icons/io5";
import { LiaPeopleCarrySolid } from "react-icons/lia";
import { BsPersonVcard } from "react-icons/bs";
import { BsPersonBoundingBox } from "react-icons/bs";
import { BsFillClipboard2PulseFill } from "react-icons/bs";
import { styled, useTheme } from "@mui/material/styles";
import { CiTimer } from "react-icons/ci";
import event from "./../../Images/event.png";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Sidebar from "../SideBar/SideBar";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex w-full h-screen flex-col  md:flex-row bg-indigo-100 dark:bg-slate-950 flex-grow-1 items-center ">
        <section className=" w-full">
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4  gap-4 m-4">
            <motion.div
              className="flex flex-col justify-center items-center w-full h-48 bg-indigo-200 dark:bg-slate-900/50 p-6 rounded-xl shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Link
                to={"/clientcontainer"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <img src={event} alt="event" width="150px" />
                <motion.div
                  initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                  animate={{ opacity: 1, x: 0 }} // Final position
                  transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                  className="text-indigo-950 dark:text-slate-400 text-xl  font-bold gradient-text"
                >
                  Create Client
                </motion.div>{" "}
              </Link>
            </motion.div>
            <motion.div
              className="flex flex-col justify-between w-full h-48 bg-white dark:bg-slate-900/50 p-6 rounded-xl shadow-lg "
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.1 }}
            >
              {" "}
              <div className="flex w-full items-center justify-between">
                <motion.div
                  initial={{
                    rotate: 0,
                    color: ["#4A90E2", "#50E3C2", "#F5A623", "#D0021B"],
                  }} // Initial rotation state on page load
                  animate={{ rotate: 360 }} // Rotating the icon on page load
                  transition={{
                    rotate: { duration: 2, ease: "linear", repeat: 0 }, // Continuous rotation
                    color: { duration: 2, repeat: Infinity, ease: "linear" }, // Continuous color change
                    scale: { duration: 0.2, ease: "easeInOut" }, // Smooth scaling
                  }}
                >
                  <IoPieChartOutline className="text-4xl" />
                </motion.div>
                {/* <div className="flex items-center justify-center w-10 h-10 dark:text-slate-400 bg-indigo-200 dark:bg-slate-900/50 rounded-full">
                  <CiMenuKebab />
                </div> */}
              </div>
              <motion.div
                initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                animate={{ opacity: 1, x: 0 }} // Final position
                transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                className="text-indigo-950 dark:text-slate-400 text-4xl  font-bold gradient-text"
              >
                {" "}
                500
              </motion.div>{" "}
              <div>
                <motion.div
                  initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                  animate={{ opacity: 1, x: 0 }} // Final position
                  transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                  className="text-indigo-950 dark:text-slate-400 text-xl font-bold  gradient-text"
                >
                  {" "}
                  Total Events
                </motion.div>{" "}
              </div>
            </motion.div>
            <motion.div
              className="flex flex-col justify-between w-full h-48 bg-white dark:bg-slate-900/50 p-6 rounded-xl shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.1 }}
            >
              {" "}
              <div className="flex w-full items-center justify-between">
                <motion.div
                  initial={{
                    rotate: 0,
                    color: ["#4A90E2", "#50E3C2", "#F5A623", "#D0021B"],
                  }} // Initial rotation state on page load
                  animate={{ rotate: 360 }} // Rotating the icon on page load
                  transition={{
                    rotate: { duration: 2, ease: "linear", repeat: 0 }, // Continuous rotation
                    color: { duration: 2, repeat: Infinity, ease: "linear" }, // Continuous color change
                    scale: { duration: 0.2, ease: "easeInOut" }, // Smooth scaling
                  }}
                >
                  <LiaPeopleCarrySolid className="text-4xl text-indigo-950 dark:text-slate-400" />
                </motion.div>{" "}
                {/* <div className="flex items-center justify-center dark:text-slate-400 w-10 h-10 bg-indigo-200 dark:bg-slate-900 rounded-full">
                  <CiMenuKebab />
                </div> */}
              </div>
              <motion.div
                initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                animate={{ opacity: 1, x: 0 }} // Final position
                transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                className="text-indigo-950 dark:text-slate-400 text-4xl  font-bold gradient-text"
              >
                {" "}
                674
              </motion.div>{" "}
              <div>
                <motion.div
                  initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                  animate={{ opacity: 1, x: 0 }} // Final position
                  transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                  className="text-indigo-950 dark:text-slate-400 text-xl font-bold  gradient-text"
                >
                  Total Clients
                </motion.div>
              </div>
            </motion.div>{" "}
            <motion.div
              className="flex flex-col justify-between w-full h-48 bg-white dark:bg-slate-900/50 p-6 rounded-xl shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.1 }}
            >
              {" "}
              <div className="flex w-full items-center justify-between">
                <motion.div
                  initial={{
                    rotate: 0,
                    color: ["#6a82fb", "#fc5c7d", "#F5A623", "#D0021B"],
                  }} // Initial rotation state on page load
                  animate={{ rotate: 360 }} // Rotating the icon on page load
                  transition={{
                    rotate: { duration: 2, ease: "linear", repeat: 0 }, // Continuous rotation
                    color: { duration: 2, repeat: Infinity, ease: "linear" }, // Continuous color change
                    scale: { duration: 0.2, ease: "easeInOut" }, // Smooth scaling
                  }}
                >
                  {" "}
                  <BsPersonVcard className="text-4xl text-indigo-950 dark:text-slate-400" />
                </motion.div>{" "}
              </div>
              <motion.div
                initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                animate={{ opacity: 1, x: 0 }} // Final position
                transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                className="text-indigo-950 dark:text-slate-400 text-4xl  font-bold gradient-text"
              >
                {" "}
                634
              </motion.div>{" "}
              <div>
                <motion.div
                  initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                  animate={{ opacity: 1, x: 0 }} // Final position
                  transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                  className="text-indigo-950 dark:text-slate-400 text-xl font-bold  gradient-text"
                >
                  Total Reports
                </motion.div>
              </div>
            </motion.div>{" "}
          </div>
          <div className="grid md:grid-cols-4  gap-4 m-4 w-auto">
            <motion.div
              className="flex flex-col justify-between w-full h-48 bg-white dark:bg-slate-900/50 p-6 rounded-xl shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, x: -40 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <div className="flex w-full items-center justify-between">
                <motion.div
                  initial={{
                    rotate: 0,
                    color: ["#6a82fb", "#fc5c7d", "#F5A623", "#D0021B"],
                  }} // Initial rotation state on page load
                  animate={{ rotate: 360 }} // Rotating the icon on page load
                  transition={{
                    rotate: { duration: 2, ease: "linear", repeat: 0 }, // Continuous rotation
                    color: { duration: 2, repeat: Infinity, ease: "linear" }, // Continuous color change
                    scale: { duration: 0.2, ease: "easeInOut" }, // Smooth scaling
                  }}
                >
                  {" "}
                  <BsPersonBoundingBox className="text-4xl text-indigo-950 dark:text-slate-400" />
                </motion.div>{" "}
              </div>
              <motion.div
                initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                animate={{ opacity: 1, x: 0 }} // Final position
                transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                className="text-indigo-950 dark:text-slate-400 text-4xl  font-bold gradient-text"
              >
                {" "}
                64
              </motion.div>{" "}
              <div className="flex items-center justify-between w-full">
                <motion.div
                  initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                  animate={{ opacity: 1, x: 0 }} // Final position
                  transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                  className="text-indigo-950 dark:text-slate-400 text-xl font-bold  gradient-text"
                >
                  New Clients
                </motion.div>
              </div>
            </motion.div>{" "}
            <div className="col-span-4 md:col-span-3 row-span-2 bg-white dark:bg-slate-900/50 p-6 rounded-xl shadow-lg">
              <Chart />
            </div>
            <motion.div
              className="flex flex-col justify-between w-full h-48 bg-white dark:bg-slate-900/50 p-6 rounded-xl shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, x: -40 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <div className="flex w-full items-center justify-between">
                <motion.div
                  initial={{
                    rotate: 0,
                    color: ["#6a82fb", "#fc5c7d", "#F5A623", "#D0021B"],
                  }} // Initial rotation state on page load
                  animate={{ rotate: 360 }} // Rotating the icon on page load
                  transition={{
                    rotate: { duration: 2, ease: "linear", repeat: 0 }, // Continuous rotation
                    color: { duration: 2, repeat: Infinity, ease: "linear" }, // Continuous color change
                    scale: { duration: 0.2, ease: "easeInOut" }, // Smooth scaling
                  }}
                >
                  {" "}
                  <BsFillClipboard2PulseFill className="text-4xl text-indigo-950 dark:text-slate-400" />
                </motion.div>{" "}
              </div>
              <motion.div
                initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                animate={{ opacity: 1, x: 0 }} // Final position
                transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                className="text-indigo-950 dark:text-slate-400 text-4xl  font-bold gradient-text"
              >
                {" "}
                6
              </motion.div>{" "}
              <div className="flex items-center justify-between w-full">
                <motion.div
                  initial={{ opacity: 0, x: -20 }} // Text slides in from the left
                  animate={{ opacity: 1, x: 0 }} // Final position
                  transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }} // Delay to sync with the number animation
                  className="text-indigo-950 dark:text-slate-400 text-xl font-bold  gradient-text"
                >
                  Invoice OverDue
                </motion.div>
              </div>
            </motion.div>{" "}
          </div>
        </section>
        <section
          className=" bg-indigo-200 dark:bg-slate-900/20 rounded-lg "
          style={{ width: "34%", height: "72%" }}
        >
          <div className="flex flex-col m-4 ">
            <div className="flex flex-col gap-5 m-4 text-indigo-900 dark:text-slate-400  ">
              <motion.div
                className="flex gap-4 items-center justify-between bg-white dark:bg-slate-900/50 p-2  w-full rounded-xl shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                {" "}
                <div className="flex items-center justify-center rounded-full w-8 h-8 bg-indigo-300 dark:bg-slate-950">
                  <CiTimer />
                </div>
                <div>
                  <div>
                    <div className="font-semibold  text-sm">
                      Event Name: Marriage Party
                    </div>
                    <div className="text-indigo-500 text-xs">
                      March 7th at 4:50Pm
                    </div>
                  </div>
                </div>
                <h1 className="px-2  rounded-lg text-sm bg-yellow-100 mt-4 text-center text-yellow-500">
                  Upcoming
                </h1>
              </motion.div>
            </div>
          </div>
          <div className="flex flex-col m-4 ">
            <div className="flex flex-col gap-5 m-4 text-indigo-900 dark:text-slate-400  ">
              <motion.div
                className="flex gap-4 items-center justify-between bg-white dark:bg-slate-900/50 p-2  w-full rounded-xl shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                {" "}
                <div className="flex items-center justify-center rounded-full w-8 h-8 bg-indigo-300 dark:bg-slate-950">
                  <CiTimer />
                </div>
                <div>
                  <div>
                    <div className="font-semibold  text-sm">
                      Event Name: Marriage Party
                    </div>
                    <div className="text-indigo-500 text-xs">
                      March 7th at 4:50Pm
                    </div>
                  </div>
                </div>
                <h1 className="px-2  rounded-lg text-sm bg-yellow-100 mt-4 text-center text-yellow-500">
                  Upcoming
                </h1>
              </motion.div>
            </div>
          </div>
          <div className="flex flex-col m-4 ">
            <div className="flex flex-col gap-5 m-4 text-indigo-900 dark:text-slate-400  ">
              <motion.div
                className="flex gap-4 items-center justify-between bg-white dark:bg-slate-900/50 p-2  w-full rounded-xl shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                {" "}
                <div className="flex items-center justify-center rounded-full w-8 h-8 bg-indigo-300 dark:bg-slate-950">
                  <CiTimer />
                </div>
                <div>
                  <div>
                    <div className="font-semibold  text-sm">
                      Event Name: Marriage Party
                    </div>
                    <div className="text-indigo-500 text-xs">
                      March 7th at 4:50Pm
                    </div>
                  </div>
                </div>
                <h1 className="px-2  rounded-lg text-sm bg-yellow-100 mt-4 text-center text-yellow-500">
                  Upcoming
                </h1>
              </motion.div>
            </div>
          </div>
          <div className="flex flex-col m-4 ">
            <div className="flex flex-col gap-5 m-4 text-indigo-900 dark:text-slate-400  ">
              <motion.div
                className="flex gap-4 items-center justify-between bg-white dark:bg-slate-900/50 p-2  w-full rounded-xl shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                {" "}
                <div className="flex items-center justify-center rounded-full w-8 h-8 bg-indigo-300 dark:bg-slate-950">
                  <CiTimer />
                </div>
                <div>
                  <div>
                    <div className="font-semibold  text-sm">
                      Event Name: Marriage Party
                    </div>
                    <div className="text-indigo-500 text-xs">
                      March 7th at 4:50Pm
                    </div>
                  </div>
                </div>
                <h1 className="px-2  rounded-lg text-sm bg-yellow-100 mt-4 text-center text-yellow-500">
                  Upcoming
                </h1>
              </motion.div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
