import React, { useState, useEffect } from "react";
import {
  FaSearch,
  FaCalendarAlt,
  FaEye,
  FaPlus,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from "../SearchInput/SearchInput";
import Sidebar from "../SideBar/SideBar";
import Modal from "react-modal";
import { getEnquiries, updateEnquiry } from "../../Services/Enquiry";
import { useNavigate } from "react-router-dom";
import ButtonBox from "./../ButtonBox/ButtonBox";
import "./Enquiry.css";
import InputField from './../InputField/InputField';

// Required for accessibility reasons
Modal.setAppElement("#root");

const ViewEnquiry = () => {
  const [enquiryDetails, setEnquiryDetails] = useState({
    id: "",
    client_name: "",
    contact_number: "",
    email: "",
    event_name: "",
    event_date: "",
    event_budget: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [enquiries, setEnquiries] = useState([]);
  const [filteredEnquiries, setFilteredEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null); // To track the selected enquiry for the popup
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal open/close
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 4;
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterEnquiries = () => {
    let filtered = enquiries;

    filtered = filtered.filter(
      (enquiry) =>
        enquiry &&
        (searchTerm
          ? enquiry.client_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          enquiry.client_id?.full_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          enquiry.event_name?.toLowerCase().includes(searchTerm.toLowerCase())
          : true) &&
        (startDate ? new Date(enquiry.event_date) >= startDate : true) &&
        (endDate ? new Date(enquiry.event_date) <= endDate : true)
    );

    setFilteredEnquiries(filtered);
    setCurrentPage(1); // Reset to the first page when filter is applied
  };
  const fetchEnquiries = async () => {
    try {
      const response = await getEnquiries();
      setEnquiries(response.result);
    } catch (error) {
      console.error("Error fetching enquiries:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEnquiries();
  }, []);

  useEffect(() => {
    filterEnquiries();
  }, [searchTerm, startDate, endDate, enquiries]);


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEnquiry(null);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(filteredEnquiries.length / entriesPerPage);

  // Slice the filteredEnquiries based on the current page
  const paginatedEnquiries = filteredEnquiries.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );
  const handleStatusChange = async (enquiryId, newStatus) => {
    try {
      const updatedEnquiry = await updateEnquiry(enquiryId, {
        status: newStatus,
      });
      setEnquiries((prevEnquiries) =>
        prevEnquiries.map((enquiry) =>
          enquiry._id === enquiryId
            ? { ...enquiry, status: newStatus }
            : enquiry
        )
      );
    } catch (error) {
      console.error("Error updating enquiry status:", error);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEnquiryDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const openModal = (enquiry) => {
    setSelectedEnquiry(enquiry);

    setEnquiryDetails({
      id: enquiry._id,
      client_name: enquiry.client_id?.full_name,
      contact_number: enquiry.client_id?.contact_number,  // Ensure this matches the state property
      email: enquiry.client_id?.email,
      event_name: enquiry.event_name,
      event_date: enquiry.event_date ? new Date(enquiry.event_date).toISOString().split('T')[0] : "",
      event_budget: enquiry.event_budget,
    });

    setIsModalOpen(true);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const saveChanges = async () => {
    try {
      const response = updateEnquiry(enquiryDetails?.id, enquiryDetails);


      alert("Enquiry updated successfully");
      setEditMode(false);
      closeModal();

      console.log("Enquiry updated successfully", response.data);

    } catch (error) {
      console.error("Error updating Enquiry", error);


    }
  }

  return (
    // <div className="flex bg-indigo-100">
    //   <Sidebar />
    //   <div className="min-h-screen p-4 flex-grow-1 mt-20 w-full event-content">
    //     <div className="">
    <div className="w-full  px-4 view-enquiry">
      <div className="flex justify-center items-center w-full mb-6">
        <h2 className="text-4xl font-bold text-black mx-3">View Enquiry</h2>
      </div>

      <div className="flex flex-col md:flex-row justify-between w-full gap-4">
        <div className="mt-3 w-full">
          <SearchInput
            placeholder="Search by client name and event name"
            onChange={handleSearchChange}
            style={{ width: "100%" }}
          />
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4 w-full">
          <div className="w-full md:max-w-xs">
            <label className="block text-lg font-medium text-gray-800 mb-2">
              From
            </label>
            <div className="relative w-full">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                className="w-full py-2 pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
              />
              <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
            </div>
          </div>

          <div className="w-full md:max-w-xs">
            <label className="block text-lg font-medium text-gray-800 mb-2">
              To
            </label>
            <div className="relative w-full">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                className="w-full py-2 pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
              />
              <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full border border-gray-300 rounded-lg">
          <thead className="rounded-2xl">
            <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
              <th className=" px-6    w-24 py-4     text-left textbold text-black uppercase tracking-wider">
                Sr.No
              </th>
              <th className=" px-6    w-48 py-4 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Client Name
              </th>
              <th className=" px-6    w-48 py-4 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Contact
              </th>
              <th className=" px-6    w-32 py-4 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Event Name
              </th>
              <th className=" px-6    w-24 py-4 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Event Date
              </th>
              <th className=" px-6    w-32 py-4 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Event Budget
              </th>
              <th className=" px-6    w-32 py-4 text-left text-4xs font-bold text-black uppercase tracking-wider">
                Proposal Status
              </th>
              <th className=" px-6    w-24 py-4 text-left text-4xs font-bold text-black uppercase tracking-wider">
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedEnquiries.length > 0 ? (
              paginatedEnquiries.map((enquiry, index) => (
                <tr
                  key={enquiry._id}
                  className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300"
                >
                  <td className=" px-6    w-24 py-4 text-left text-2xs font-medium text-black uppercase tracking-wider">
                    {(currentPage - 1) * entriesPerPage + index + 1}
                  </td>
                  <td className=" px-6    w-48 py-4 text-left text-2xs font-medium text-black tracking-wider">
                    {enquiry.client_id?.full_name}
                  </td>
                  <td className=" px-6    w-48 py-4 text-left text-2xs font-medium text-black tracking-wider">
                    {enquiry.client_id?.contact_number}
                  </td>
                  <td className=" px-6    w-32 py-4 text-left text-2xs font-medium text-black tracking-wider">
                    {enquiry.event_name}
                  </td>
                  <td className=" px-6    w-24 py-4 text-left text-2xs font-medium text-black tracking-wider">
                    {enquiry.event_date
                      ? new Date(enquiry.event_date).toLocaleDateString()
                      : "N/A"}
                  </td>
                  <td className=" px-6     w-32 py-4 text-left text-2xs font-medium text-black tracking-wider">
                    {enquiry.event_budget}
                  </td>
                  <td className="px-6 py-2  text-2xs font-medium text-black tracking-wider">
                    <select
                      value={enquiry.status ?? "NotDone"}
                      onChange={(e) =>
                        handleStatusChange(enquiry._id, e.target.value)
                      }
                      className="bg-white border border-gray-300 rounded px-2 py-1"
                    >
                      <option value="Not Done">Not Done</option>
                      <option value="Done">Done</option>

                    </select>
                  </td>
                  <td className=" px-6     w-24 py-4 text-left text-2xs font-medium text-black tracking-wider">
                    <button
                      onClick={() => openModal(enquiry)}
                      className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                    >
                      <FaEye />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="8"
                  className=" px-6   py-4 text-center text-gray-500"
                >
                  No enquiries found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination  flex justify-center items-center mt-4 ">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            <FaChevronLeft />
          </button>
          <div className="pagination-info mx-2">
            {currentPage} of {totalPages}
          </div>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            <FaChevronRight />
          </button>
        </div>

        {selectedEnquiry && (
  <Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    contentLabel="Enquiry Details"
    className="modal-content fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg z-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <h2 className="text-xl font-bold mb-4 flex justify-center modal-header">
      Enquiry Details
    </h2>
    <div className="flex flex-col items-center">
      <div className="w-full">
        <div className="mb-4">
          <InputField
            label="Client Name"
            type="text"
            name="client_name"
            value={enquiryDetails.client_name}
            onChange={handleInputChange}
            className="modal-input"
            readOnly={!editMode}
          />
        </div>
        <div className="mb-4">
          <InputField
            label="Contact Number"
            type="text"
            name="contact_number"
            value={enquiryDetails.contact_number}
            onChange={handleInputChange}
            className="modal-input"
            readOnly={!editMode}
          />
        </div>
        <div className="mb-4">
          <InputField
            label="Email"
            type="email"
            name="email"
            value={enquiryDetails.email}
            onChange={handleInputChange}
            className="modal-input"
            readOnly={!editMode}
          />
        </div>
        <div className="mb-4">
          <InputField
            label="Event Name"
            type="text"
            name="event_name"
            value={enquiryDetails.event_name}
            onChange={handleInputChange}
            className="modal-input"
            readOnly={!editMode}
          />
        </div>
        <div className="mb-4">
          <InputField
            label="Event Date"
            type="date"
            name="event_date"
            value={enquiryDetails.event_date}
            onChange={handleInputChange}
            className="modal-input"
            readOnly={!editMode}
          />
        </div>
        <div className="mb-4">
          <InputField
            label="Event Budget"
            type="number"
            name="event_budget"
            value={enquiryDetails.event_budget}
            onChange={handleInputChange}
            className="modal-input"
            readOnly={!editMode}
          />
        </div>
      </div>

      <div className="model-buttons flex flex-col md:flex-row justify-center gap-2 mt-4">
        {editMode ? (
          <>
            <ButtonBox
              label="Save"
              onClick={saveChanges}
              className="button-box bg-blue-600 text-white"
            >
              Save
            </ButtonBox>
            <ButtonBox
              label="Close"
              onClick={closeModal}
              className="button-box bg-gray-600 text-white"
            >
              Cancel
            </ButtonBox>
          </>
        ) : (
          <>
            <ButtonBox
              label="Edit"
              onClick={toggleEditMode}
              className="button-box bg-blue-600 text-white"
            >
              Edit
            </ButtonBox>
            <ButtonBox
              label="Close"
              onClick={closeModal}
              className="button-box bg-gray-600 text-white"
            >
              Close
            </ButtonBox>
          </>
        )}
      </div>
    </div>
  </Modal>
)}

      </div>
    </div>
  );
};

export default ViewEnquiry;
