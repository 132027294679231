import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../SearchInput/SearchInput";
import ButtonBox from "../ButtonBox/ButtonBox";
import { getExecutives } from "../../Services/Auth";
import "./ManagerDetails.css";
import Sidebar from "../SideBar/SideBar";
import { motion } from "framer-motion";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons for pagination

const ExecutiveDetails = () => {
  const [executives, setExecutives] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [filteredExecutives, setFilteredExecutives] = useState([]); // State for filtered executives
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6; // Number of entries per page

  useEffect(() => {
    const fetchExecutives = async () => {
      try {
        const response = await getExecutives();
        const executivesArray = response.result || [];
        if (Array.isArray(executivesArray)) {
          setExecutives(executivesArray);
          setFilteredExecutives(executivesArray);
        } else {
          console.error("API did not return an array:", executivesArray);
          setExecutives([]);
          setFilteredExecutives([]);
        }
      } catch (error) {
        console.error("Failed to fetch executives", error);
        setExecutives([]);
        setFilteredExecutives([]);
      }
    };

    fetchExecutives();
  }, []);

  useEffect(() => {
    // Filter executives based on the search query
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = executives.filter(
      (executive) =>
        executive.fullname.toLowerCase().includes(lowercasedQuery) ||
        (executive.number &&
          executive.number.toLowerCase().includes(lowercasedQuery))
    );
    setFilteredExecutives(filtered);
  }, [searchQuery, executives]);

  // Pagination logic
  const totalPages = Math.ceil(filteredExecutives.length / entriesPerPage); // Calculate total pages
  const indexOfLastExecutive = currentPage * entriesPerPage;
  const indexOfFirstExecutive = indexOfLastExecutive - entriesPerPage;
  const currentExecutives = filteredExecutives.slice(
    indexOfFirstExecutive,
    indexOfLastExecutive
  );

  const handleViewProfile = (executiveId) => {
    navigate(`/executive-profile/${executiveId}`);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="w-full  px-4">
      <div className="flex justify-between items-center flex-wrap">
        <h2 className="text-4xl font-bold text-black mx-3">
          Executive Details
        </h2>

        {/* Search Input */}
        <div className="w-full sm:w-1/2 lg:w-1/3 mb-8 mx-3">
          <SearchInput
            placeholder="Search by name,contact"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 rounded-md"
          />
        </div>
      </div>

      <div className="flex flex-wrap -m-2 mx-1 ">
        {currentExecutives.length === 0 ? (
          <p>No executives available</p>
        ) : (
          currentExecutives.map((executive, index) => (
            <div
              key={index}
              className="p-2 w-full sm:w-1/2 md:w-1/3 px-4 py-4 "
            >
              <motion.div
                className="p-4 rounded-lg border-b-4 translate-x-1 border-r-4 border-white shadow-2xl"
                style={{
                  backgroundImage: "linear-gradient(135deg, #3b82f6, #1d4ed8)",
                }}
                whileHover={{
                  scale: 1.1,
                  rotate: 3,
                  backgroundImage: "linear-gradient(135deg, #60a5fa, #1e3a8a)",
                  transition: { duration: 0.5 },
                }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <p className="card-text text-white mb-2">
                  <strong>Executive Name:</strong> {executive.fullname}
                </p>
                <p className="card-text mb-2 text-white">
                  <strong>Email:</strong> {executive.email}
                </p>
                {executive.number && (
                  <p className="card-text mb-2 text-white">
                    <strong>Contact:</strong> {executive.number}
                  </p>
                )}
                <ButtonBox
                  label="View Profile"
                  style={{ height: "auto" }}
                  className="text-white font-bold py-1 px-8 rounded"
                  onClick={() => handleViewProfile(executive._id)}
                />
              </motion.div>
            </div>
          ))
        )}
      </div>

      {/* Pagination Controls */}
      {filteredExecutives.length > 0 && (
        <div className="pagination flex justify-center items-center py-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button px-4 py-2 bg-gray-200 text-gray-600 rounded-l hover:bg-blue-500 disabled:opacity-50"
          >
            <FaChevronLeft />
          </button>
          <span className="pagination-info mx-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button px-4 py-2 bg-gray-200 text-gray-600 rounded-r hover:bg-blue-500 disabled:opacity-50"
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default ExecutiveDetails;
