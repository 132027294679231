import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ViewClient.css";
import { useNavigate } from "react-router-dom";
import {
  FaSearch,
  FaCalendarAlt,
  FaEye,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import SearchInput from "../SearchInput/SearchInput";
import DatePicker from "react-datepicker";

const ViewClient = () => {
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  // Fetch all clients on component mount
  useEffect(() => {
    axios
      .get("https://demo.internsbee.in/api/clients")
      .then((response) => {
        setClients(response.data.result); // Assuming the client data is inside 'result'
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });
  }, []);

  // Navigate to individual client details page
  const viewClientDetails = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  // Filter and paginate the clients data
  const filteredClients = clients.filter((client) =>
    client.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredClients.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const totalPages = Math.ceil(filteredClients.length / entriesPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full px-4 vendor-content">
      <div className="flex justify-center items-center w-full mb-6">
        <h2 className="text-2xl sm:text-4xl font-bold text-black mx-3">View Client</h2>
      </div>

      <div className="flex flex-col sm:flex-row justify-between w-full">
        <div className="w-full sm:w-auto">
          <SearchInput
            placeholder="Search by Name"
            onChange={handleSearchChange}
            style={{ width: "100%", maxWidth: "27rem" }}
          />
        </div>
        {/* <div className="flex flex-col sm:flex-row items-center ml-4 justify-center gap-4 mt-4 sm:mt-0">
          <div className="w-full sm:w-auto max-w-xs">
            <label className="block text-lg font-medium text-gray-800 mb-2">From</label>
            <div className="relative w-full max-w-md">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                className="w-full py-2 pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
              />
              <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
            </div>
          </div>

          <div className="w-full sm:w-auto max-w-xs">
            <label className="block text-lg font-medium text-gray-800 mb-2">To</label>
            <div className="relative w-full">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                className="w-full py-2 pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
              />
              <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
            </div>
          </div>
        </div> */}
      </div>

      <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full border border-gray-300 rounded-lg">
          <thead className="rounded-2xl">
            <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
              <th className="px-6 py-2 w-12 text-left text-4xs font-bold text-black uppercase tracking-wider">Sr.No</th>
              <th className="px-6 py-2 w-24 text-left text-4xs font-bold text-black uppercase tracking-wider">Name</th>
              <th className="px-6 py-2 w-24 text-left text-4xs font-bold text-black uppercase tracking-wider">Email ID</th>
              <th className="px-6 py-2 w-24 text-left text-4xs font-bold text-black uppercase tracking-wider">Contact No.</th>
              <th className="px-6 py-2 w-28 text-left text-4xs font-bold text-black uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="relative">
            {clients.length <= 0 ? (
              <tr>
                <td colSpan="5" className="text-center py-4">No data available</td>
              </tr>
            ) : (
              currentEntries.map((client, index) => (
                <tr
                  key={client._id}
                  className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300"
                >
                  <td className="px-6 py-2 w-12 text-left text-2xs font-medium text-black tracking-wider">
                    {(currentPage - 1) * entriesPerPage + index + 1}
                  </td>
                  <td className="px-6 py-2 w-24 text-left text-2xs font-medium text-black tracking-wider">
                    {client.full_name}
                  </td>
                  <td className="px-6 py-2 w-24 text-left text-2xs font-medium text-black tracking-wider">
                    {client.email}
                  </td>
                  <td className="px-6 py-2 w-24 text-left text-2xs font-medium text-black tracking-wider">
                    {client.contact_number}
                  </td>
                  <td className="px-6 py-2 w-24 text-left text-2xs font-medium text-black tracking-wider">
                    <button
                      onClick={() => viewClientDetails(client._id)}
                      className="flex items-center justify-center bg-blue-800 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                    >
                      <FaEye className="text-lg" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {filteredClients.length > entriesPerPage && (
        <div className="pagination flex justify-center items-center py-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button px-4 py-2 bg-gray-200 text-gray-600 rounded-l hover:bg-gray-300 disabled:opacity-50"
          >
            <FaChevronLeft />
          </button>
          <span className="pagination-info mx-2">
            {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button px-4 py-2 bg-gray-200 text-gray-600 rounded-r hover:bg-gray-300 disabled:opacity-50"
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default ViewClient;
