import React, { useEffect, useState } from "react";
import "./Proposal.css";
import Dropdown from "./../Dropdown/Dropdown";
import InputField from "./../InputField/InputField";
import ButtonBox from "../ButtonBox/ButtonBox";
import { getEnquiryById } from "../../Services/Enquiry";
import { getStock } from "../../Services/Stock";
import { createProposal, getQuotationById } from "../../Services/ProposalApi";
import ProposalInput from "./ProposalInput";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../SideBar/SideBar";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Proposal = ({ }) => {
  const { id } = useParams();
  const [terms, setTerms] = useState([
    "The confirmation of the artist depends on first-come-first-serve basis in terms of booking amounts.",
    "Amount once paid are non-refundable with any other date or event.",
    "100% Guarantee cannot be given on technical equipment.",
    "There would be use of Artificial Flowers unless mentioned separately.",
    "All Cheques / DD to be paid favoring 'Tutons Events LLP'.",
    "All necessary Permissions/Clearances required for the event & work at the Site/Venue.",
    "Payment: 50% Before the event & 50% after delivery, within 30 Days.",
    "The above Quote is valid for 60 Days from the date of Quote.",
    "18% GST is applicable on Total Billing.",
  ]);
  const [newTerm, setNewTerm] = useState("");

  const handleAddTerm = () => {
    if (newTerm.trim()) {
      setTerms([...terms, newTerm.trim()]);
      setNewTerm("");
    }
  };

  const [requirements, setRequirements] = useState([]);
  const [enquiry, setEnquiry] = useState({});
  const [stock, setStock] = useState({
    stock_Name: "",
    purchaseQuantity: "",
    rate_per_days: "",
    days: "",
  });
  const [transportType, setTransportType] = useState("");
  const [transportCharges, setTransportCharges] = useState("");
  const [services_amount, setServices_amount] = useState(0);
  const [totalStock, setTotalStock] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [grand_total, setGrandTotal] = useState(0);

  useEffect(() => {
    if (id) {
      fetchEnquiry();
    }
  }, [id]);

  const fetchEnquiry = async () => {
    try {
      const response = await getEnquiryById(id);
      setEnquiry(response?.result);
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddStock = () => {
    const amount = stock.purchaseQuantity * stock.rate_per_days * stock.days;
    if (amount > 0) {
      setRequirements([...requirements, { ...stock, amount }]);
      updateTotals();
      setStock({ stock_Name: "", purchaseQuantity: "", rate_per_days: "", days: "" }); // Ensure field names match
    } else {
      alert("Please enter valid stock details.");
    }
  };

  const handleAddTransport = () => {
    // Update totals and other calculations with transport charges
    updateTotals();
  };

  const updateTotals = () => {
    // Ensure all values used in calculations are numbers
    const stockTotal = requirements.reduce((sum, req) => sum + (parseFloat(req.amount) || 0), 0);

    // Calculate CGST and SGST (assuming 9% each)
    const cgstAmount = stockTotal * 0.09;
    const sgstAmount = stockTotal * 0.09;

    // Ensure transportCharges is a valid number or default to 0
    const validTransportCharges = parseFloat(transportCharges) || 0;

    // Ensure services_amount is a valid number or default to 0
    const validServicesAmount = parseFloat(services_amount) || 0;

    // Grand total calculation ensuring all values are numbers
    const grand_total =
      stockTotal +
      validTransportCharges +
      validServicesAmount +
      cgstAmount +
      sgstAmount;

    // Set state values
    setTotalStock(stockTotal);
    setCgst(cgstAmount);
    setSgst(sgstAmount);
    setGrandTotal(grand_total);
  };


  useEffect(() => {
    updateTotals();
  }, [requirements, transportCharges, services_amount]);



  const handleSaveProposal = async () => {
    const proposalData = {
      enquiry_id: enquiry._id,
      customerName: enquiry.customer_name,
      requirements: requirements,
      transportType,
      transportCharges,
      services_amount,
      sub_total: totalStock,
      cgst,
      sgst,
      grand_total,
    };

    console.log("Proposal data being sent:", proposalData); // Log the data to be sent

    try {
      await createProposal(proposalData); // Save proposal
      alert("Proposal saved successfully!");
    } catch (error) {
      console.error("Error saving proposal:", error);
      alert("Error saving proposal.");
    }
  };
 
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const margin = 15;
    const padding = 10;
    const smallFontSize = 10;
  
    // Set Font Size for Title
    doc.setFontSize(16);
    const title = "Quotation";
    const titleWidth = doc.getStringUnitWidth(title) * doc.internal.scaleFactor;
    const titleX = (doc.internal.pageSize.width - titleWidth) / 2;
    doc.text(title, titleX, margin + padding + 10);
  
    // Add horizontal line after the title
    const lineY = margin + padding + 15;
    doc.line(margin, lineY, doc.internal.pageSize.width - margin, lineY);
  
    // Set Font Size for Date and Time
    doc.setFontSize(12);
  
    const pageWidth = doc.internal.pageSize.width;
    const leftMargin = margin + padding;
    const rightMargin = pageWidth - margin - padding;
    const topMargin = margin + padding;
  
    const date = new Date();
    const currentDate = date.toLocaleDateString();
    const currentTime = date.toLocaleTimeString();
  
    doc.text(`Date: ${currentDate}`, rightMargin, topMargin + 25, { align: "right" });
    doc.text(`Time: ${currentTime}`, rightMargin, topMargin + 35, { align: "right" });
  
    const clientRows = [
      ["Customer Name", enquiry?.client_id?.full_name || "N/A"],
      ["Event Date", enquiry?.event_date || "N/A"],
      ["Event Venue", enquiry?.event_venue || "N/A"]
    ];
  
    const additionalTopMargin = 20;
  
    // Generate the client details table
    doc.autoTable({
      body: clientRows,
      startY: margin + padding + additionalTopMargin,
      margin: { left: leftMargin },
      theme: 'grid',
      tableWidth: 'wrap',
    });
  
    // Add Stock Table
    const stockColumns = ["Stock", "Quantity", "Price", "Days", "Amount"];
    const stockRows = requirements.map((req) => [
      req.stock_Name,
      req.purchaseQuantity,
      req.rate_per_days,
      req.days,
      req.amount,
    ]);
  
    const yAfterEnquiryTable = doc.lastAutoTable.finalY + 10;
  
    doc.autoTable({
      head: [stockColumns],
      body: stockRows,
      startY: yAfterEnquiryTable,
      margin: { left: leftMargin, right: margin },
    });
  
    // Add Totals and Other Info in Table Format
    const yAfterStockTable = doc.lastAutoTable.finalY + 10;
    const pointsMargin = 30; // 40px converted to points (approximately)
  
    const totalsRows = [
      ["Transport Type", transportType],
      ["Services", services_amount],
      ["Transport Charges", transportCharges],
      ["Stock Total", totalStock],
      ["CGST (9%)", cgst],
      ["SGST (9%)", sgst],
      ["Grand Total", grand_total]
    ];
  
    doc.autoTable({
      body: totalsRows,
      startY: yAfterStockTable,
      margin: { left: pointsMargin, right: pointsMargin }, // Apply 40px margin in points
      theme: 'grid',
      tableWidth: 'auto',
    });
  
    // Add Terms and Conditions Section
    const termsY = doc.lastAutoTable.finalY + 20;
    doc.setFontSize(smallFontSize);
    doc.text("Terms and Conditions", leftMargin, termsY);
  
    // Numbering and adding Terms
    const termsWithNumbers = terms.map((term, index) => `${index + 1}. ${term}`);
    console.log('Terms with Numbers:', termsWithNumbers); // Debugging
  
    let currentY = termsY + 10;
    const lineHeight = 5; // Adjust line height for minimal spacing
  
    termsWithNumbers.forEach((term) => {
      const termLines = doc.splitTextToSize(term, pageWidth - 2 * leftMargin);
      termLines.forEach((line) => {
        doc.text(line, leftMargin, currentY);
        currentY += lineHeight; // Adjust the line height as needed
      });
    });
  
    // Draw Border
    doc.rect(margin, margin, pageWidth - 2 * margin, doc.internal.pageSize.height - 2 * margin);
  
    // Save the PDF
    doc.save("proposal.pdf");
  };
  
  
  



  return (
    <div className="flex proposal">
      <Sidebar />
      <div className=" bg-indigo-100 flex-grow-1 items-center mt-14 h-full w-full">
        <div className="min-h-screen flex flex-col justify-start p-4 ">
          <div className="heading-proposal flex justify-between items-center w-full max-w-4xl mb-2">
            <h1 className="text-4xl font-bold text-gray-800">Proposal</h1>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-10 p-4">
            <p className="text-center md:text-left">Event Name: {enquiry?.event_name}</p>
            <p className="text-center md:text-left">Client Name: {enquiry.client_id?.full_name || "N/A"}</p>
            <p className="text-center md:text-left">Event Date: {enquiry?.event_date}</p>
          </div>

          {/* Stock dropdown and input fields */}
          <div className="search-inputs-container flex gap-4 md:gap-7">
            <div className="stock-vendor-dropdown">
              <InputField
                label="Select Stock"
                placeholder="e.g:Sound"
                value={stock.stock_Name}
                onChange={(e) => setStock({ ...stock, stock_Name: e.target.value })} // Updated field name to match state
              />

            </div>
            <div className="flex gap-4 search-input-fields">
              <ProposalInput
                label="Quantity"
                type="number"
                value={stock.purchaseQuantity}
                onChange={(e) =>
                  setStock({ ...stock, purchaseQuantity: +e.target.value })
                }
              />
              <ProposalInput
                label="Price"
                type="number"
                value={stock.rate_per_days}
                onChange={(e) => setStock({ ...stock, rate_per_days: +e.target.value })}
              />
              <ProposalInput
                label="Days"
                type="number"
                value={stock.days}
                onChange={(e) => setStock({ ...stock, days: +e.target.value })}
              />
              <ProposalInput
                label="Amount"
                value={stock.purchaseQuantity * stock.rate_per_days * stock.days}
                readOnly
              />
            </div>
          </div>
          <div className="hr-line mt-0">
            <hr className="border-t-2 mb-4" />
          </div>
          <div className="add-stock-btn">
            <ButtonBox
              label="Add Stock"
              className="p-4"
              onClick={handleAddStock}
            />
          </div>
          {/* Proposal Table */}
          <div className="overflow-x-auto w-full mt-4">
            <table className="min-w-full border border-gray-300 rounded-lg">
              <thead className="rounded-2xl">
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
                  <th className="px-6  py-2   text-left text-4xs font-bold text-black uppercase tracking-wider">
                    Sr. No</th>
                  <th className="px-6  py-2   text-left text-4xs font-bold text-black uppercase tracking-wider">Stock</th>
                  <th className="px-6  py-2   text-left text-4xs font-bold text-black uppercase tracking-wider">Quantity</th>
                  <th className="px-6  py-2   text-left text-4xs font-bold text-black uppercase tracking-wider">Price</th>
                  <th className="px-6  py-2   text-left text-4xs font-bold text-black uppercase tracking-wider">Days</th>
                  <th className="px-6  py-2   text-left text-4xs font-bold text-black uppercase tracking-wider">Amount</th>
                </tr>
              </thead>
              <tbody>
                {requirements.map((req, index) => (
                  <tr key={index} className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300"
                  >
                    <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                      {index + 1}</td>
                    <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{req.stock_Name}</td>
                    <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{req.purchaseQuantity}</td>
                    <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{req.rate_per_days}</td>
                    <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{req.days}</td>
                    <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{req.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center">
              <ButtonBox
                label="Add Total Stock"
                className="p-4"
                onClick={handleAddTransport}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center p-4">
            <div className="md:ml-10 flex flex-col md:flex-row gap-8 md:gap-20 w-full">
              <div className="p-4 w-full md:w-1/2 lg:w-1/3">
                <div>
                  <div className="p-2">
                    <InputField
                      label="Transport Type"

                      value={transportType}
                      onChange={(e) => setTransportType(e.target.value)}
                    />
                  </div>
                  <div className="p-2">
                    <InputField
                      label="Transport Charges"
                      type="number"
                      value={transportCharges}
                      onChange={(e) => setTransportCharges(e.target.value)}
                    />
                  </div>
                </div>

                <div className="p-2">
                  <InputField
                    label="Services Amount"
                    type="textarea"
                    value={services_amount}
                    onChange={(e) => setServices_amount(e.target.value)}
                  />
                </div>
              </div>

              <div className="proposal-container p-4 w-full md:w-auto">
                <div
                  className="terms-box p-4 mb-4 border border-blue-800 rounded-lg bg-white"
                  style={{ width: "100%", maxWidth: "40rem" }}
                >
                  <h2 className="text-xl font-bold mb-2">
                    Terms and Conditions
                  </h2>
                  <div className="max-h-40 overflow-y-auto border border-blue-800 p-2">
                    <ul className="list-disc list-inside">
                      {terms.map((term, index) => (
                        <li key={index}>{term}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="add-term-container mt-4 flex gap-10">
                    <div className="p-1">
                      <InputField
                        label="Add New Term"
                        placeholder="Enter a new term..."
                        value={newTerm}
                        onChange={(e) => setNewTerm(e.target.value)}
                      />
                    </div>
                    <ButtonBox
                      label="Add Term"
                      className="mt-2 p-2"
                      onClick={handleAddTerm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto w-full mt-4">

            <table className="min-w-full border border-gray-300 rounded-lg">
              <tbody className="rounded-2xl">
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">

                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    <strong>Transport Type: </strong>
                  </td>
                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{transportType}</td>
                </tr>
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">

                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    <strong>Transport Charges: </strong>
                  </td>
                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{transportCharges}</td>
                </tr>
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">

                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    <strong>Services amount: </strong>
                  </td>
                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{services_amount}</td>
                </tr>
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">

                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    <strong>Stock Total: </strong>
                  </td>
                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{totalStock}</td>
                </tr>
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">

                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    <strong>CGST (9%): </strong>
                  </td>
                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{cgst}</td>
                </tr>
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">

                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    <strong>SGST (9%): </strong>
                  </td>
                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{sgst}</td>
                </tr>
                <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">

                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">
                    <strong>Grand Total: </strong>
                  </td>
                  <td className="px-6  py-2   text-left text-2xs font-medium text-black  tracking-wider">{grand_total}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-center gap-10 p-4">
            <ButtonBox
              label="Save"
              className="p-4"
              onClick={handleSaveProposal}
            />

            <ButtonBox label="Download PDF" className="p-4" onClick={handleDownloadPDF} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proposal;
