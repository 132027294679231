
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./EventReport.css";
// Helper function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (!isNaN(date)) {
    return date.toLocaleDateString("en-GB"); // Formats to "dd/MM/yyyy"
  }
  return "Invalid Date";
};

const PaymentReport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 4;
  // Fetch payment data from API
  const fetchPayments = async () => {
    try {
      const response = await axios.get(
        "https://demo.internsbee.in/api/advancepayment"
      );
      setPayments(response.data.data);
      setFilteredPayments(response.data.data); // Initialize with all data
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  // Filter logic for search, start date, and end date
  useEffect(() => {
    applyFilters();
  }, [searchTerm, startDate, endDate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const applyFilters = () => {
    let filtered = payments;
  
    // Filter by search term (client name or event name)
    if (searchTerm.trim()) {
      filtered = filtered.filter(
        (payment) =>
          payment.enquiry?.client_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          payment.enquiry?.event_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }
  
    // Filter by start date ("From")
    if (startDate) {
      const start = new Date(startDate.setHours(0, 0, 0, 0));
      filtered = filtered.filter((payment) => {
        const eventDate = new Date(payment.enquiry?.event_date); // Add optional chaining for enquiry
        return !isNaN(eventDate) && eventDate >= start;
      });
    }
  
    // Filter by end date ("To")
    if (endDate) {
      const end = new Date(endDate.setHours(23, 59, 59, 999));
      filtered = filtered.filter((payment) => {
        const eventDate = new Date(payment.enquiry?.event_date); // Add optional chaining for enquiry
        return !isNaN(eventDate) && eventDate <= end;
      });
    }
  
    setFilteredPayments(filtered);
  };
  

  const handleExportToExcel = () => {
    const exportData = filteredPayments.map((payment, index) => ({
      "Sr.No": index + 1,
      "Client Name": payment.enquiry?.client_name || "N/A",
      "Event Name": payment.enquiry?.event_name || "N/A",
      "Event Date": formatDate(payment.enquiry?.event_date) || "N/A",
      "Venue": payment.enquiry?.event_venue || "N/A",
      "Guest Quantity": payment.enquiry?.guest_quantity || "N/A",
      "Quotation Amount": payment.quotation?.grand_total || "N/A",
      "Status": payment.quotation?.payment_status || "N/A",
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    worksheet["!cols"] = [
      { wch: 5 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "PaymentReport");
  
    XLSX.writeFile(workbook, "Event_Report.xlsx");
  };
  
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(filteredPayments.length / entriesPerPage);

  // Slice the filteredPayments based on the current page
  const paginatedEnquiries = filteredPayments.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );
  return (
    <>
      <div className="w-full  px-4 eventreport-content">
        <div className="flex justify-center items-center w-full mb-6">
          <h2 className="text-4xl font-bold text-black mx-3">
            Event Reports
          </h2>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <div className="flex flex-col md:flex-row items-center gap-4 mt-3">
            <div className="flex items-center gap-2">
              <label className="mr-1">From:</label>
              <input
                type="date"
                value={startDate ? startDate.toISOString().split("T")[0] : ""}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                className="p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="mr-1">To:</label>
              <input
                type="date"
                value={endDate ? endDate.toISOString().split("T")[0] : ""}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                className="p-2 border border-gray-300 rounded"
              />
            </div>
          </div>
          <div className="flex items-center">
            <p className="mr-4">Total Events: {filteredPayments.length}</p>
            <button
              className="p-2 bg-blue-800 hover:bg-blue-400 text-white font-bold py-3 px-8 rounded-full"
              onClick={handleExportToExcel}
            >
              Export to Excel
            </button>
          </div>
        </div>

        <div className="overflow-x-auto w-full mt-4">
          <table className="min-w-full border border-gray-300 rounded-lg">
            <thead className="rounded-2xl">
              <tr className="flex justify-between relative border border-gray-300 rounded-lg mb-1 bg-white w-full">
                <th className="px-6  w-24 py-2 text-left textbold text-black uppercase tracking-wider">
                  Sr.No
                </th>
                <th className="px-6  w-24 py-2 text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Client Name
                </th>
                <th className="px-6  w-24 py-2 text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Event Name
                </th>
                <th className="px-6  w-24 py-2 text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Event Date
                </th>
                <th className="px-6  w-24 py-2 text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Venue
                </th>
                <th className="px-6  w-24 py-2 text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Guest Quantity
                </th>
                <th className="px-6  w-24 py-2 text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Quotation Amount
                </th>
                <th className="px-6  w-28 py-2 text-left text-4xs font-bold text-black uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedEnquiries.length > 0 ? (
                paginatedEnquiries.map((item, index) => (
                  <tr
                    key={item._id}
                    className="flex relative justify-between w-full border border-gray-300 rounded-lg mb-1 bg-white hover:bg-blue-300 hover:scale-[1.02] hover:-translate-y-1 hover:border-blue-400 transition-all duration-300"
                  >
                    <td className="px-6  w-24 py-2 text-left text-2xs font-medium text-black tracking-wider">
                      {index + 1 + (currentPage - 1) * entriesPerPage}{" "}
                      {/* Adjusted for pagination */}
                    </td>
                    <td className="px-6  w-24 py-2 text-left text-2xs font-medium text-black tracking-wider">
                      {item.enquiry?.client_id?.full_name}
                    </td>
                    <td className="px-6  w-24 py-2 text-left text-2xs font-medium text-black tracking-wider">
                      {item.enquiry?.event_name}
                    </td>
                    <td className="px-6  w-24 py-2 text-left text-2xs font-medium text-black tracking-wider">
                      {formatDate(item.enquiry?.event_date)}
                    </td>
                    <td className="px-6  w-24 py-2 text-left text-2xs font-medium text-black tracking-wider">
                    {item.enquiry?.event_venue}
                    </td>
                    <td className="px-6  w-24 py-2 text-left text-2xs font-medium text-black tracking-wider">
                    {item.enquiry?.guest_quantity}
                    </td>
                    <td className="px-6  w-24 py-2 text-left text-2xs font-medium text-black tracking-wider">
                    {item.quotation.grand_total}
                    </td>
                    <td className="px-6  w-28 py-2 text-left text-2xs font-medium text-black tracking-wider">
                    {item.quotation.payment_status}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="px-6   py-2 text-center">
                    No payments found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination  flex justify-center items-center mt-8">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              <FaChevronLeft />
            </button>
            <div className="pagination-info mx-2">
              {currentPage} of {totalPages}
            </div>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentReport;
